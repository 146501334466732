import React from "react";

const CenteredSpinner = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        className="spinner-border text-primary"
        style={{ alignSelf: "center" }}
      ></div>
    </div>
  );
};

export default CenteredSpinner;
